.root {
  --color: hsla(var(--base-100), 0.2);
  background-color: var(--color);
  color: var(--text-color);
  padding: 2px 8px;
  border-radius: 6px;
  white-space: nowrap;
  cursor: default;
  user-select: none;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  &.color-blue {
    --color: hsl(var(--hsl-blue-h) var(--hsl-blue-s) var(--hsl-blue-l) / 0.13);
    --text-color: hsl(var(--hsl-blue-h) var(--hsl-blue-s) var(--hsl-blue-l));
  }
  &.color-purple {
    --color: hsl(var(--hsl-purple-h) var(--hsl-purple-s) var(--hsl-purple-l) / 0.13);
    --text-color: hsl(var(--hsl-purple-h) var(--hsl-purple-s) var(--hsl-purple-l));
  }
  &.color-orange {
    --color: hsl(var(--hsl-orange-h) var(--hsl-orange-s) var(--hsl-orange-l) / 0.13);
    --text-color: hsl(var(--hsl-orange-h) var(--hsl-orange-s) var(--hsl-orange-l));
  }
  &.color-green {
    --color: hsl(var(--hsl-green-h) var(--hsl-green-s) var(--hsl-green-l) / 0.13);
    --text-color: hsl(var(--hsl-green-h) var(--hsl-green-s) var(--hsl-green-l));
  }
}

.sm {
  font-size: smaller;
}

.xs {
  font-size: 12px;
  padding: 0px 4px;
  font-weight: 600;
}

.lg {
  padding: 8px 16px;
}

.variant-solid {
  background-color: var(--color);
}

.variant-outline {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--color);
}

.variant-mixed {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--color);
  color: var(--color);
  font-weight: 600;
}

.color-default {
  --color: hsla(var(--base-100), 0.2);
}

.color-contrast {
  --color: black;
  color: white;
}

.color-red {
  --color: var(--red);
}

.color-default {
  --color: hsla(var(--base-100), 0.2);
}

.btn {
  cursor: pointer;
  transition:
    background-color 0.2s,
    box-shadow 0.2s;
  &:hover {
    --color: hsla(var(--base-100), 0.4);
  }
}

:global(.dark) {
  .color-contrast {
    background-color: white;
    color: black;
  }
}
