.root {
  display: grid;
  grid-template-columns: 24px 1fr max-content;
  padding: var(--item-pad);
  border-radius: var(--item-radius);
  align-items: center;
  gap: 12px;
  font-weight: 500;
  transition: background-color 0.2s;
  color: var(--text-color);
  font-size: 15px;
  cursor: pointer;
  &:focus {
    color: inherit;
  }
  &:hover {
    background-color: #ebebef86;
  }
  > div:first-child {
    justify-items: center;
  }
  &[data-selected='true'] {
    color: var(--color-fg-default);
    background-color: #ebebef;
  }
  &[data-subitem='true'] {
    display: block;
    padding: 6px 6px 6px 44px;
    // padding-left: 38px;
    &[data-selected='true'] {
      background-color: #ebebef86;
    }
  }
}
