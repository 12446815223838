.root {
  // display: block;
  // // max-width: -moz-fit-content;
  // max-width: fit-content;
  // // margin: 0 auto;
  // overflow-x: auto;
  white-space: nowrap;

  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

.tableRow {
  [data-file-actions] {
    visibility: hidden;
  }
  &:hover {
    background-color: var(--background4);
    [data-file-actions] {
      visibility: visible;
    }
  }
  td {
    padding: 10px;
    border-top: 1px solid rgb(231, 233, 238);
  }
}

.tableRowExpanded {
  background-color: var(--background4);
}
