.root {
  display: grid;
  gap: 12px;
  justify-items: center;
  text-align: center;
  height: 100%;
  align-items: center;
  align-content: center;
  h4 {
    font-weight: 600;
    font-size: 1.25rem;
  }
  > .actions {
    display: flex;
    gap: 12px;
  }
}
