$lightGreen: #4bbc7d;
$lightGreen2: #61d47f;
$darkBlue: #191c22;
$darkBlue2: #21252c;
$darkBlue3: #16191e;
$darkBlue4: #2d323b;
$background12: #f2f8f3;

$colors: (
  'lightGreen': $lightGreen,
  'lightGreen2': $lightGreen2,
  'green': #16a43c,
  'red': #ff2d6c,
  'amber': #d69a07,
  'gradient': linear-gradient(to right, #53dada, $lightGreen2),
  'gradientBright': linear-gradient(to right, #2cf3f3, #3ff06e),
  'gradientNotAccessible1': linear-gradient(to right, #409c94, #479962),
  'gradientNotAccessible2': linear-gradient(to right, #b1dbdb, #ace1ba),
  'gradientNotAccessible3': linear-gradient(to right, #aaede8, #afeac2),
  'darkGradient': linear-gradient(90deg, $darkBlue 0%, $darkBlue4 100%),
  'darkGradient2': linear-gradient(90deg, $darkBlue3 0%, $darkBlue4 100%),
  'lightGradient': linear-gradient(90deg, $background12 0%, #f8f9f8 100%),
  'lightGradient2': linear-gradient(90deg, #cdd3cf96 0%, #efefefb0 100%),
  'conicGrad':
    conic-gradient(
      from 180deg at 50% 50%,
      $darkBlue -91.02deg,
      $lightGreen 0.35deg,
      #d57661 57deg,
      $darkBlue 268.98deg,
      $lightGreen 360.35deg
    ),
  'blue': #53dada,
  'base-100': '150deg, 1%, 58%',
  'base-200': '140deg, 1%, 40%',
  'base-300': '135deg, 3%, 30%',
  'base-400': '135deg, 3%, 24%',
  'base-500': '135deg, 4%, 19%',
);

$gradients: (
  'conic-grad-def':
    conic-gradient(
      from 180deg at 50% 50%,
      #191c22 -91.02deg,
      #4bbc7d 0.35deg,
      #d57661 57deg,
      #191c22 268.98deg,
      #4bbc7d 360.35deg
    ),
);

$light_colors: (
  'color-navbar': #252926,
  'color-fg-default': #252926,
  'color-border': rgba(94, 119, 133, 0.2),
  'color-border-active': #252926,
  'background1': #252926,
  'background2': #444945,
  'background3': #ffffff,
  'background4': #fbfcfb,
  'background5': #9a9e9c,
  'background6': #a1a7a2,
  'background7': #cdd3cf,
  'background8': #efefef,
  'background9': #ecefed,
  'background10': #f8f9f8,
  'background11': #fbfcfb,
  'background12': $background12,
  'background13': #ffffff,
  'background14': #0c0c0f,
  'background15': #0c0c0f,
  'background16': #ffffff,
  'background17': #f8f9f8,
  'background18': #939594,
  'background19': #e4fae9,
  'shadow1': rgba(94, 119, 133, 0.25),
  'shadow2': rgba(55, 80, 94, 0.1),
  'shadow3': rgba(94, 119, 133, 0.4),
  'shadowLine': rgba(94, 119, 133, 0.2),
  'screenBg': #fff,
  'lightgrey': #94a3b8,
  'sky100': #f8f9fe,
);

$dark_colors: (
  'color-navbar': #f0f6fc,
  'color-fg-default': #c9d1d9,
  'color-border': rgba(201, 209, 217, 0.2),
  'color-border-active': #f0f6fc,
  'background1': #0c0c0f,
  'background2': #16191e,
  'background3': $darkBlue2,
  'background4': $darkBlue4,
  'background5': #545964,
  'background6': #686b77,
  'background7': #a7aab1,
  'background8': #efefef,
  'background9': #ecefed,
  'background10': #f8f9f8,
  'background11': #fbfcfb,
  'background12': $background12,
  'background13': $darkBlue4,
  'background14': #fff,
  'background15': #acb5bd,
  'background16': #16191e,
  'background17': $darkBlue2,
  'background18': #ecefed,
  'background19': #16191e,
  'sky100': $darkBlue2,
  'shadow1': rgba(94, 119, 133, 0),
  'shadow2': rgba(55, 80, 94, 0.1),
  'shadow3': rgba(94, 119, 133, 0.25),
  'shadowLine': rgba(94, 119, 133, 0.15),
  'screenBg': #0c0c0f,
  'lightgrey': #efefef,
);

$light_gradients: (
  'linear-right':
    linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1)
    ),
  'linear-asc':
    linear-gradient(
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0)
    ),
  'linear-dark-desc': linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 1)),
);

$dark_gradients: (
  'linear-right':
    linear-gradient(
      to right,
      rgba(22, 25, 30, 0),
      rgba(22, 25, 30, 0.25),
      rgba(22, 25, 30, 0.5),
      rgba(22, 25, 30, 1),
      rgba(22, 25, 30, 1)
    ),
  'linear-asc':
    linear-gradient(
      rgba(12, 12, 16, 1),
      rgba(12, 12, 16, 0.75),
      rgba(12, 12, 16, 0.5),
      rgba(12, 12, 16, 0.25),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
  'linear-dark-desc': linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 1)),
);

$fontThin: 100;
$fontExtraLight: 200;
$fontLight: 300;
$fontNormal: 400;
$fontMedium: 500;
$fontSemiBold: 600;
$fontBold: 700;
$fontExtraBold: 800;
$fontBlack: 900;

$fontBase: 16px;
$fontXs: 11px;
$fontSm: 12px;
$fontMd: 14px;
$fontLg: 18px;
$fontXl: 20px;
$fontHero: 41px;

$fontH1: 36px;
$fontH2: 28px;
$fontH3: 22px;
$fontH4: 20px;
$fontH5: 18px;
$fontH6: 16px;
$fontH7: 14px;
$fontH8: 11px;

$radiusSm: 0.125rem;
$radiusMd: 0.25rem;
$radiusLg: 0.5rem;
$radiusFull: 9999px;
