@import 'styles/media-queries';

.root {
  --item-pad: 8px;
  --item-radius: 6px;
  --text-color: #666377;
  width: var(--sidebar-w);
  left: 0;
  top: 0;
  border-right: 1px solid var(--base-border-color);
  display: grid;
  height: 100vh;
  align-content: space-between;
  background-color: #fafafa;
  user-select: none;
  overflow-y: auto;
  z-index: 1;
  &:not([data-fixedwidth='true']) {
    position: sticky;
  }

  > div {
    padding: var(--grid-base) calc(var(--grid-base) * 1);
    display: grid;
    gap: var(--grid-base);
  }

  .listContainer {
    display: grid;
    gap: var(--item-pad);
    svg {
      width: 20px;
    }
  }

  .top {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr max-content;
    padding: var(--item-pad);

    > a > span:last-child {
      display: none;
    }
  }

  .sectionTitle {
    font-weight: 500;
    opacity: 0.7;
    // font-size: 0.9rem;
  }

  &:not([data-fixedwidth='true']) {
    @include lt-laptop {
      width: 80px;

      > div {
        padding: 12px;
        justify-items: center;
      }

      .listContainer {
        a {
          width: 47px;
          height: 47px;
          grid-template-columns: 1fr;
          justify-items: center;
          align-items: center;

          > span:not(:first-child) {
            display: none;
          }
        }
      }

      .top {
        > div {
          display: none;
        }
        > a > span {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: inherit;
          }
        }
      }

      .userProfile {
        padding: 0;
        width: 47px;
        height: 47px;
        grid-template-columns: 1fr;
        > span:first-child > svg {
          width: 47px;
        }

        > img {
          width: 100%;
        }

        > div,
        > svg {
          display: none;
        }
      }

      .sectionTitle {
        text-indent: -500px;
        height: 2px;
        width: 47px;
        background-color: var(--base-border-color);
      }
    }
  }
}

.userProfile {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  padding: var(--item-pad);
  border-radius: var(--item-radius);
  gap: 8px;
  align-items: center;
  background-color: white;
  box-shadow: inset 0 0 0 1px var(--base-border-color);

  > span:first-child > svg {
    width: 34px;
  }

  > div {
    justify-items: start;
    line-height: 1rem;
    overflow: hidden;
    text-overflow: unset;
    white-space: nowrap;

    > div:first-child {
      font-weight: 500;
    }

    > div:last-child {
      opacity: 0.7;
      font-size: 0.8rem;
    }
  }

  &:hover {
    background-color: #ebebef86;
  }
}

.subMenuList {
  --item-pad: 8px;
  --item-radius: 6px;
  display: grid;
  gap: 8px;
  padding: 8px;

  &[data-menu='true'] {
    > a {
      padding: 8px;
    }
  }
}

.subMenuTrigger {
  display: grid;
  padding: var(--item-pad);
  border-radius: var(--item-radius);
  align-items: center;
  gap: 12px;
  transition: background-color 0.2s;
  color: var(--text-color);
  justify-items: center;

  &:hover {
    background-color: #ebebef86;
  }

  &[data-selected='true'] {
    color: var(--color-fg-default);
    background-color: #ebebef;
  }
}

.groupMenu {
  display: grid;
  gap: 6px;

  .groupSubMenu {
    display: none;
  }

  &[data-open='true'] {
    .groupSubMenu {
      display: grid;
      gap: 6px;
    }
  }
}
