@import 'styles/media-queries';

.root {
  display: grid;
  grid-template-columns: max-content 1fr;
  .main {
    display: grid;
  }

  @include lt-sm {
    padding-top: 60px;
    .main {
      > div {
        height: calc(100vh - 60px);
      }
    }
  }
}
