@import 'styles/media-queries';
@import 'styles/variables';

.root {
  --pad: 60px;
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 1fr;
  > div {
    &:first-child {
      display: grid;
      grid-template-rows: min-content 1fr;
      padding: var(--pad);
      overflow-y: auto;
      > div:last-child {
        display: grid;
        align-items: center;
        justify-content: center;
        padding-top: var(--pad);
        > div {
          width: 480px;
        }
        @include lt-md {
          align-items: start;
          min-width: auto;
          justify-content: stretch;
          > div {
            width: 100%;
          }
        }
      }
    }
    &:last-child {
      position: relative;
      overflow: hidden;
      background-color: var(--background7);
      padding: var(--pad);
      display: grid;
      align-items: end;
      color: white;
    }
  }

  @include lt-md {
    --pad: 24px;
    grid-template-columns: 1fr;
    > div {
      &:first-child {
        row-gap: 32px;
      }
      &:last-child {
        display: none;
      }
    }
  }

  @include lt-md-h {
    --pad: 24px;
  }
}

.imageText {
  color: white;
  position: relative;
  z-index: 3;
  display: grid;
  row-gap: 42px;
  font-size: $fontHero;
  font-weight: $fontBold;
  line-height: normal;
  > div:last-child {
    opacity: 0.8;
  }
}

.fader {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(37, 41, 38, 0.06) 0%, rgba(37, 41, 38, 0.8) 100%);
}
